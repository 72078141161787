<template>
  <v-img
    v-if="namespace === 'Home'"
    :height="$vuetify.breakpoint.xsOnly ? '100vh' : '70vh'"
    gradient="to top, rgba(255, 255, 255, .35), rgba(255, 255, 255, .60)"
    src="/static/hero.jpg"
  >
    <v-container
      fill-height
      fluid
      :px-12="$vuetify.breakpoint.mdAndUp"
    >
      <v-row align-center>
        <v-slide-x-transition appear>
          <v-col>
            <h1
              class="text-uppercase font-weight-bold mb-4"
              :class="[$vuetify.breakpoint.xsOnly ? 'display-1' : 'display-3']"
            >
              Σχολη οδηγων
            </h1>

            <div
              class="mb-4"
              :class="[$vuetify.breakpoint.xsOnly ? 'headline' : 'display-2 font-weight-light']"
            >
              Μαθαίνετε να οδηγείτε με ασφάλεια
            </div>

            <v-responsive
              max-width="600"
              class="mb-5"
            >
              Η Σχολή Οδηγών Σταύρος Πλυτάς ιδρύθηκε το 1987 στην Αγία Παρασκευή, έχοντας πολυετή εμπειρία στον τομέα θεωρητικής και πρακτικής εκπαίδευσης υποψηφίων οδηγών.
            </v-responsive>

            <v-btn
              large
              class="px-5"
              color="success lighten-1"
              :to="`/contact-me`"
            >
              Επικοινωνηστε τωρα
            </v-btn>
          </v-col>
        </v-slide-x-transition>
      </v-row>
    </v-container>
  </v-img>

  <v-sheet
    v-else
    :min-height="$vuetify.breakpoint.smAndDown ? '45vh' : '300px'"
    color="grey lighten-3"
    height="20vh"
  >
    <v-container fill-height>
      <v-row
        align-content="center"
        justify="center"
        class="fill-height"
      >
        <v-col cols="12">
          <h1
            class="display-2 text-center"
            v-text="title"
          />
        </v-col>

        <v-breadcrumbs
          :items="breadcrumbs"
          class="grey--text pb-0 px-3"
        />
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
  export default {
    computed: {
      breadcrumbs () {
        const home = { text: 'Αρχική', to: '/' }

        switch (this.namespace) {
          case 'About': return [home, { text: 'Σχετικά με εμάς' }]
          case 'Contact': return [home, { text: 'Επικοινωνήστε μαζί μας' }]
          case 'Work': return [home, { text: 'Υπηρεσίες' }]
          case 'AutoDiploma': return [home, { text: 'Δίπλωμα Αυτοκινήτου' }]
          case 'fortigoDiploma': return [home, { text: 'Δίπλωμα Φορτηγού' }]
          case 'leofDiploma': return [home, { text: 'Δίπλωμα Λεωφορείου' }]
          case 'taxiDiploma': return [home, { text: 'Δίπλωμα Ταξί' }]
          case 'epagelmatikoDiploma': return [home, { text: 'Δίπλωμα Επαγγελματικό' }]
          case 'amDiploma': return [home, { text: 'Δίπλωμα Κατηγορίας ΑΜ' }]
          case 'a1Diploma': return [home, { text: 'Δίπλωμα Κατηγορίας Α1' }]
          case 'a2Diploma': return [home, { text: 'Δίπλωμα Κατηγορίας Α2' }]
          case 'aDiploma': return [home, { text: 'Δίπλωμα Κατηγορίας Α' }]
          case 'ntalikaDiploma': return [home, { text: 'Δίπλωμα Ρυμουλκούμενου' }]
          case 'ananewsh': return [home, { text: 'Ανανέωση διπλώματος' }]
          case 'antikatastash': return [home, { text: 'Αντικατάσταση Άδειας Παλαιού Τύπου' }]
          case 'nea_adeia_kloph_apwleia': return [home, { text: 'Χορήγηση Νέας Άδειας (κλοπή/απώλεια)' }]
          case 'psifiakos_taxografos': return [home, { text: 'Έκδοση Ψηφιακού Ταχογράφου' }]
          case 'adeia_kykloforias': return [home, { text: 'Άδειες Κυκλοφορίας' }]
          case 'metekpedeush': return [home, { text: 'Μετεκπαίδευση' }]
          case 'seso': return [home, { text: 'Επαναχορήγηση Λόγω ΣΕΣΟ' }]
          case 'mh_xorhghsh_pei': return [home, { text: 'Μη Χορήγηση Π.Ε.Ι' }]
          case 'metatroph_kseni_xora': return [home, { text: 'Μετατροπή Από Ξένη Χώρα' }]
          case 'metatropi_elvetia': return [home, { text: 'Μετατροπή Ελβετικής Σε Ελληνική' }]
          case 'vivlia': return [home, { text: 'Βιβλία Υπουργείου Μεταφορών' }]
          default: return []
        }
      },
      namespace () {
        return this.$route.name
      },
      title () {
        switch (this.namespace) {
          case 'About': return 'Σχετικά με εμάς'
          case 'Contact': return 'Επικοινωνήστε μαζί μας'
          case 'Work': return 'Υπηρεσίες'
          case 'AutoDiploma': return 'Αυτοκίνητο'
          case 'fortigoDiploma': return 'Φορτηγό'
          case 'leofDiploma': return 'Λεωφορείο'
          case 'taxiDiploma': return 'Ταξί'
          case 'epagelmatikoDiploma': return 'Επαγγελματικά Οχήματα'
          case 'amDiploma': return 'Μηχανές Κατηγορίας ΑΜ'
          case 'a1Diploma': return 'Μηχανές Κατηγορίας Α1'
          case 'a2Diploma': return 'Μηχανές Κατηγορίας Α2'
          case 'aDiploma': return 'Μηχανές Κατηγορίας Α'
          case 'ntalikaDiploma': return 'Ρυμουλκούμενου'
          case 'ananewsh': return 'Ανανέωση διπλώματος'
          case 'antikatastash': return 'Αντικατάσταση Άδειας Παλαιού Τύπου'
          case 'nea_adeia_kloph_apwleia': return 'Χορήγηση Νέας Άδειας (κλοπή/απώλεια)'
          case 'psifiakos_taxografos': return 'Έκδοση Ψηφιακού Ταχογράφου'
          case 'adeia_kykloforias': return 'Άδειες Κυκλοφορίας'
          case 'metekpedeush': return 'Μετεκπαίδευση'
          case 'seso': return 'Επαναχορήγηση Λόγω ΣΕΣΟ'
          case 'mh_xorhghsh_pei': return 'Μη Χορήγηση Π.Ε.Ι'
          case 'metatroph_kseni_xora': return 'Μετατροπή Από Ξένη Χώρα'
          case 'metatropi_elvetia': return 'Μετατροπή Ελβετικής Σε Ελληνική'
          case 'vivlia': return 'Βιβλία Υπουργείου Μεταφορών'
          default: return ''
        }
      }
    }
  }
</script>
